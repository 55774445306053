<!--
 * @Author: cyy
 * @version: 1.4.25
 * @Date: 2022-10-18 14:28:59
 * @LastEditTime: 2022-11-08 15:45:51
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @Description:智能机器人列表-全部
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\allData.vue
-->
<template>
  <div class="allData">
    <!-- 顶部搜索 -->
    <div class="header mb10">
      <el-row style="width:100%">
        <el-col :span="8">
          <div class="flex-center" v-if="!isChatRecord">
            <el-button type="primary" @click="upload" size="medium">
              添加机器人
            </el-button>
            <changePhotoimg
              :isshow="true"
              :isCheckbox="true"
              :limit="Number(upper_limit)"
              :hadselectNum="Number(count)"
              ref="changePhotoimg"
              pictureTitle="添加图片(多选)"
              @complete="completePhoto"
              class="changePhotoimg"
            ></changePhotoimg>
            <span class="tips">数量：{{ count }}/{{ upper_limit }}</span>
          </div>
        </el-col>
        <el-col :span="8" :offset="isChatRecord?4:0">
          <div class="flex-center" style="justify-content:flex-end">
            <span class="tips mr10" style="flex-shrink:0">分组</span>
            <el-select
              class="mr20"
              clearable
              v-model="formData.gids"
              style="width:100%;flex:1 "
              size="medium"
              placeholder="请选择"
              multiple
              @change="selectChange"
            >
              <el-option
                v-for="item in selectGroupList"
                :key="item.gid"
                :label="item.gname"
                :value="item.gid"
              ></el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="isChatRecord?12:8">
          <div class="flex-center" style="justify-content:flex-end">
            <span class="tips mr10 " style="flex-shrink:0">关键词</span>
            <el-input
              clearable
              @keyup.enter.native="toSearch"
              style="width: 338px"
              size="medium"
              v-model="formData.search"
              class="input-with-select  "
              :placeholder="
                formData.search_type == 2 || formData.search_type == ''?'输入机器人名称搜索'
                  :formData.search_type == 3?'输入创建人名称搜索'
                  :'输入机器人ID搜索'
              "
            >
              <el-select
                size="medium"
                v-model="formData.search_type"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="机器人名称" value="2"></el-option>
                <el-option label="机器人ID" value="1"></el-option>
                <el-option label="创建人名称" value="3"></el-option>
              </el-select>
            </el-input>
            <el-button
              type="primary"
              class="ml20"
              @click="toSearch"
              style="width:74px"
              size="medium"
            >
              搜索
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <pagination2
      ref="child"
      :option="formData2"
      url="/liveRobot/robotList"
      @complete="complete"
      class="pagination2"
    >
      <template>
        <el-table
          ref="multipleTable"
          @sort-change="sortChange"
          :data="completeData"
          :header-cell-style="{
            background: 'rgba(245,245,245,1)',
            color: '#333333',
          }"
          @selection-change="handleSelectionChange"
          @row-click="rowClick"
        >
          <el-table-column label width="30" v-if="isSingleSelect">
            <template slot-scope="scope">
              <el-radio :label="scope.row.robot_id" v-model="radioId">
                &nbsp;
              </el-radio>
            </template>
          </el-table-column>
          <el-table-column
            min-width="45px"
            type="selection"
            v-else
          ></el-table-column>
          <el-table-column prop="robot_id" label="机器人ID" width="100px" />
          <el-table-column label="机器人">
            <template slot-scope="{ row }">
              <div class=" robot_contain">
                <div class="tu">
                  <img :src="row.photo" alt="" />
                </div>
                <div class="name hidetext">{{ row.name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="分组名称">
            <template slot-scope="{ row }">
              {{ row.gname || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="uname">
            <template slot-scope="{ row }">
              {{ row.uname || '-' }}
            </template>
          </el-table-column>
          <el-table-column label="创建时间" sortable="custom">
            <template slot-scope="{ row }">
              {{ row.c_time | formatTimeStamp('yyyy-MM-dd hh:mm:ss') }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="250" v-if="!isChatRecord">
            <template slot-scope="scope">
              <el-button size="mini" type="text" @click="goEdit(scope.row)">
                编辑
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button size="mini" type="text" @click="grouping(scope.row)">
                分组
              </el-button>
              <el-divider direction="vertical"></el-divider>
              <el-button
                size="mini"
                type="text"
                @click="del(1, [scope.row.robot_id])"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <div class="footer">
          <template v-if="isChatRecord">
            <div style="text-align: right;width: 100%;">
              <el-button @click="close" size="medium" style="width: 74px">
                取消
              </el-button>
              <el-button type="primary" size="medium" @click="next" style="width: 74px">
                下一步
              </el-button>
            </div>
          </template>
          <template v-else>
            <el-checkbox
              v-model="overdue"
              :true-label="1"
              :false-label="2"
              class="ml10"
              :indeterminate="isIndeterminate"
              @change="checkALL"
            >
              <div class="tips ml10">已选择{{ selectList.length }}条</div>
            </el-checkbox>
            
            <el-button
              size="medium"
              style="width:130px"
              @click="
                del(
                  2,
                  selectList.map(item => item.robot_id)
                )
              "
              class="mr10 ml20"
            >
              批量删除
            </el-button>
            <el-button size="medium" style="width:130px" @click="groupings">
              批量分组
            </el-button>
          </template>
        </div>
      </template>
    </pagination2>

    <!-- 添加机器人 -->
    <add-robot
      v-if="status"
      @addComplete="addComplete"
      :addRobotList="addRobotList"
      :dialogstatus.sync="status"
    ></add-robot>

    <!-- 分组 -->
    <for-group
      v-if="groupStatus"
      :groupList="groupList"
      :dialogstatus.sync="groupStatus"
      @groupComplete="groupComplete"
      :groupingVal="groupingVal"
    ></for-group>
  </div>
</template>

<script>
import forGroup from './forGroup'
import addRobot from './addRobot'
import changePhotoimg from '@cm/base/changePhotoimg'
export default {
  components: {
    changePhotoimg,
    addRobot,
    forGroup,
  },
  props:{
    // 是否话术本
    isChatRecord:Boolean,
    //true是单选 false:多选
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      radioId:null,
      rowInfo:{},
      upper_limit: 0,
      groupingVal: {},
      count: 0,
      addRobotList: [],
      groupList: [],
      selectGroupList: [],
      groupStatus: false,
      status: false,
      completeData: [],
      isIndeterminate: false,
      selectList: [],
      overdue: 2,
      formData: {
        gids: ['0'],
        search_type: '2',
        search: '',
        sort: '1',
        is_page: 1,
      },
      formData2: {
        gids: ['0'],
        search_type: '2',
        search: '',
        sort: '2',
        is_page: 1,
      },
    }
  },

  created() {
    this.getGroupList()
  },

  methods: {
    selectChange(val) {
      const index = val.findIndex(item => item == 0)
      // console.log(index == 0, val[index] == 0)
      if (val.length > 1 && index > -1) {
        if (index == 0 && val[index] == 0) {
          this.formData.gids.splice(0, 1)
        } else {
          this.formData.gids = ['0']
        }
      }

      if (val.length == 0) {
        this.formData.gids = ['0']
      }
    },
    // 删除机器人
    async del(type, val) {
      if (type == 2 && this.selectList.length == 0) {
        this.$root.prompt('请先选择机器人')
        return
      }
      this.$confirm('确定删除已选机器人？', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async () => {
          await this.$http({
            url: '/liveRobot/delRobot',
            data: { robot_ids: val },
            successMsg: true,
          })
          this.$refs.child.reset()
        })
        .catch(() => {})
    },

    groupComplete() {
      this.groupStatus = false
      this.$refs.child.reset()
    },

    // 分组
    grouping(val) {
      this.groupingVal = {
        isSinggle: true,
        data: [val],
      }
      this.groupStatus = true
    },

    // 批量分组
    groupings() {
      if (this.selectList.length == 0) {
        this.$root.prompt('请先选择机器人')
        return
      }
      this.groupingVal = {
        isSinggle: false,
        data: this.selectList,
      }
      this.groupStatus = true
    },

    // 编辑
    goEdit(val) {
      this.addRobotList = {
        gid: val.gid,
        isedit: true,
        robot_id: val.robot_id,
        list: [
          {
            name: val.name,
            photo: val.photo,
            id: Math.random(),
          },
        ],
      }

      this.$nextTick(() => {
        this.status = true
      })
    },

    // 获取列表数据
    complete({ data, count, upper_limit }) {
      this.completeData = data
      this.count = count
      this.upper_limit = upper_limit
    },

    // 全选
    checkALL(val) {
      if (val == 1) {
        this.$refs.multipleTable.toggleAllSelection()
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },

    // 列表选择的数据
    handleSelectionChange(val) {
      this.selectList = val
      this.isIndeterminate =
        this.selectList.length > 0 &&
        this.completeData.length != this.selectList.length
      if (
        this.selectList.length > 0 &&
        this.completeData.length == this.selectList.length
      ) {
        this.overdue = 1
      } else {
        this.overdue = 2
      }
      this.$forceUpdate()
    },
    // 单选
    rowClick(row){
      this.rowInfo = row
    },
    sortChange(val) {
      this.formData2.sort = !val.order
        ? ''
        : val.order === 'ascending'
        ? '1'
        : '2'
    },

    toSearch() {
      this.formData2 = _.assign({}, this.formData)
    },

    // 获取分组数据
    async getGroupList() {
      const { data } = await this.$http({
        url: '/pcUser/getGroupList',
        data: { user_type: 2, type: 2 },
      })
      this.selectGroupList = _.merge([], data)
      this.selectGroupList.unshift({
        gid: '0',
        gname: '全部',
      })

      this.groupList = data
    },

    addComplete() {
      this.status = false
      this.$refs.child.reset()
    },

    completePhoto(val) {
      this.addRobotList = {
        gid: '',
        isedit: false,
        list: val.map(item => {
          return {
            name: '',
            photo: item.url,
            id: Math.random(),
          }
        }),
      }
      this.$nextTick(() => {
        this.status = true
      })
    },
    // 选择图片
    upload() {
      this.$refs.changePhotoimg.changePicture()
    },
    // 关闭
    close(){
      this.$emit('close')
    },
    // 下一步
    next(){
      if(!this.radioId){
        this.$root.prompt('请先选择机器人')
      } else {
        this.$emit('next',this.rowInfo)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.allData {
  img {
    width: 100%;
    height: 100%;
  }
  .changePhotoimg {
    height: 0 !important;
    width: 0 !important;
  }

  .tips {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    white-space: nowrap;

    ::v-deep .input-with-select {
      .el-input__inner {
        color: #333333;
      }
      .el-input-group__prepend {
        background-color: #fff;
        width: 80px;
      }
    }
  }
  .el-divider {
    background: rgba(27, 157, 151, 1);
  }
  .pagination2 {
    .robot_contain {
      display: flex;
      align-items: center;

      .tu {
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        border-radius: 4px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        margin-left: 10px;
      }
    }
    .footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
