<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-10-18 17:06:54
 * @LastEditTime: 2022-11-02 18:18:13
 * @LastEditors: cyy
 * @Description: 
 * @FilePath: \teacherdingding.com\src\views\liveRobot\components\forGroup.vue
-->
<template>
  <div class="forGroup">
    <el-dialog
      class="dialogVisible"
      width="422px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title">
        <div class="title">
          <div class="singgle" v-if="groupingVal.isSinggle">
            <div class="tu"><img :src="newpost.photo" alt="" /></div>
            <div class="name">{{ newpost.name }}</div>
          </div>
          <div v-else class="more">批量分组</div>
        </div>
      </div>
      <div class="flex-center">
        <span class="tips">分组</span>
        <el-select
          class="mr20"
          v-model="gid"
          style="width:321px "
          size="medium"
          clearable
          placeholder="请选择"
        >
          <el-option
            v-for="item in groupList"
            :key="item.gid"
            :label="item.gname"
            :value="item.gid"
          ></el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">
          取 消
        </el-button>
        <el-button type="primary" @click="submit" size="medium">
          保 存
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
    groupingVal: {
      type: Object,
      default: () => {},
    },
    groupList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      newpost: {},
      gid: '',
      robot_ids: [],
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.groupingVal.isSinggle) {
        this.newpost = this.groupingVal.data[0]
        this.gid =
          this.groupingVal.data[0].gid && this.groupingVal.data[0].gid == 0
            ? ''
            : this.groupingVal.data[0].gid
        this.robot_ids = [this.groupingVal.data[0].robot_id]
      } else {
        this.robot_ids = this.groupingVal.data.map(item => item.robot_id)
      }
    })
  },

  methods: {
    async submit() {
      await this.$http({
        url: '/liveRobot/setRobotGroup',
        data: {
          robot_ids: this.robot_ids,
          gid: this.gid,
        },
        successMsg: true,
      })
      this.$emit('groupComplete')
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.forGroup {
  .tips {
    flex-shrink: 0;
    font-size: 14px;
    color: #333333;
    margin-right: 30px;
  }
  img {
    width: 100%;
    height: 100%;
  }
  ::v-deep .el-dialog__body {
    padding-top: 0 !important;
  }

  .title {
    .singgle {
      display: flex;
      .tu {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        margin-right: 10px;
      }
      .name {
        font-size: 14px;
        color: #333333;
      }
    }
    .more {
      font-size: 18px;
      color: #333333;
    }
  }
}
</style>
